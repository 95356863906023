import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const initialState = {
  name: "",
  greetingMessage: "",
  complationMessage: "",
  outOfHoursMessage: "",
  ratingMessage: "",
  isDefault: false,
  token: "",
  provider: "beta",
  useNPS: false,
  expiresTicketNPS: 3,
  expiresTicket: 0,
  timeToTransfer: null,
  useTypebot: false,
  sessionName: null,
};

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();

  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [selectedQueueId, setSelectedQueueId] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);
        setWhatsApp(data);

        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds);
        setSelectedQueueId(data.transferQueueId);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  const handleSaveWhatsApp = async (values) => {
    const whatsappData = { ...values, queueIds: selectedQueueIds, transferQueueId: selectedQueueId };
    delete whatsappData["queues"];
    delete whatsappData["session"];

    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        await api.post("/whatsapp", whatsappData);
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
    setSelectedQueueId(null);
    setSelectedQueueIds([]);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {whatsAppId
            ? i18n.t("whatsappModal.title.edit")
            : i18n.t("whatsappModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>

                <Grid spacing={1} container >
                  <Grid item sm={4}>
                    <Field
                      fullWidth
                      as={TextField}
                      label={i18n.t("whatsappModal.form.name")}
                      autoFocus
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />

                  </Grid>

                  <Grid item sm={5} >
                    <Field
                      fullWidth
                      as={TextField}
                      label={'Encerrar chat após x (horas)'}
                      name="expiresTicket"
                      error={touched.expiresTicket && Boolean(errors.expiresTicket)}
                      helperText={touched.expiresTicket && errors.expiresTicket}
                      variant="outlined"
                      margin="dense"
                      className={classes.textFieldTime}
                    />
                  </Grid>

                  <Grid item sm={3} style={{ alignItems: 'center' }}>
                    <FormControlLabel
                      style={{ alignItems: 'center' }}
                      control={
                        <Field
                          as={Switch}
                          color="primary"
                          name="isDefault"
                          checked={values.isDefault}
                        />
                      }
                      label={i18n.t("whatsappModal.form.default")}
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.greetingMessage")}
                      type="greetingMessage"
                      multiline
                      rows={4}
                      fullWidth
                      name="greetingMessage"
                      error={
                        touched.greetingMessage && Boolean(errors.greetingMessage)
                      }
                      helperText={
                        touched.greetingMessage && errors.greetingMessage
                      }
                      variant="outlined"
                      margin="dense"
                    />
                  </Grid>


                  <Grid item sm={12}>
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.complationMessage")}
                      type="complationMessage"
                      multiline
                      rows={4}
                      fullWidth
                      name="complationMessage"
                      error={
                        touched.complationMessage &&
                        Boolean(errors.complationMessage)
                      }
                      helperText={
                        touched.complationMessage && errors.complationMessage
                      }
                      variant="outlined"
                      margin="dense"
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.outOfHoursMessage")}
                      type="outOfHoursMessage"
                      multiline
                      rows={4}
                      fullWidth
                      name="outOfHoursMessage"
                      error={
                        touched.outOfHoursMessage &&
                        Boolean(errors.outOfHoursMessage)
                      }
                      helperText={
                        touched.outOfHoursMessage && errors.outOfHoursMessage
                      }
                      variant="outlined"
                      margin="dense"
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.ratingMessage")}
                      type="ratingMessage"
                      multiline
                      rows={4}
                      fullWidth
                      name="ratingMessage"
                      error={
                        touched.ratingMessage && Boolean(errors.ratingMessage)
                      }
                      helperText={touched.ratingMessage && errors.ratingMessage}
                      variant="outlined"
                      margin="dense"
                    />
                  </Grid>


                  <Grid item sm={12}>
                    <QueueSelect
                      selectedQueueIds={selectedQueueIds}
                      onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                    />
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <FormControlLabel
                        control={
                          <Field
                            as={Switch}
                            color="primary"
                            name="useTypebot"
                            checked={values.useTypebot}
                          />
                        }
                        label={'Usar Typebot'}
                      />
                    </Grid>

                    <Grid item sm={6}>
                      <Field
                        fullWidth
                        as={TextField}
                        label={'Typebot'}
                        name="sessionName"
                        error={touched.sessionName && Boolean(errors.sessionName)}
                        helperText={touched.sessionName && errors.sessionName}
                        variant="outlined"
                        required={values.useTypebot}
                        margin="dense"
                        className={classes.textField}
                      />

                    </Grid>
                  </Grid>

                  <Grid item sm={6} >
                    <Field
                      fullWidth
                      type="number"
                      as={TextField}
                      label='Transferir após x (minutos)'
                      name="timeToTransfer"
                      error={touched.timeToTransfer && Boolean(errors.timeToTransfer)}
                      helperText={touched.timeToTransfer && errors.timeToTransfer}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      InputLabelProps={{ shrink: values.timeToTransfer ? true : false }}
                    />

                  </Grid>

                  <Grid item sm={6}>
                    <QueueSelect
                      selectedQueueIds={selectedQueueId}
                      onChange={(selectedId) => {
                        setSelectedQueueId(selectedId)
                      }}
                      multiple={false}
                      title={'Fila de Transferência'}
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.token")}
                      type="token"
                      fullWidth
                      name="token"
                      variant="outlined"
                      margin="dense"
                    />
                  </Grid>

                </Grid>

              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("whatsappModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  style={{ backgroundColor: "#1A4783", color: "white" }}
                  // color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {whatsAppId
                    ? i18n.t("whatsappModal.buttons.okEdit")
                    : i18n.t("whatsappModal.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
