// Lista de Contatos de Campanhas
import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react";

import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import EditIcon from "@material-ui/icons/Edit";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';
import FormatListNumberedOutlinedIcon from '@material-ui/icons/FormatListNumberedOutlined';

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactListItemModal from "../../components/ContactListItemModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import useContactLists from "../../hooks/useContactLists";
import { Grid, Menu, MenuItem, Tooltip, withStyles } from "@material-ui/core";

import planilhaExemplo from "../../assets/planilha.xlsx";
import { socketConnection } from "../../services/socket";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import SelectContactsModal from "../../components/SelectContactModal";
import * as XLSX from "xlsx";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    borderRadius: "20px"
  },
}));

const ContactListItems = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const { contactListId } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectContactsModalOpen, setSelectContactsModalOpen] = useState(false);
  const [contactListItemModalOpen, setContactListItemModalOpen] =
    useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [contactList, setContactList] = useState({});
  const [excludedContacts, setExcludedContacts] = useState([]);
  const fileUploadRef = useRef(null);

  const { findById: findContactList } = useContactLists();

  useEffect(() => {
    findContactList(contactListId).then((data) => {
      setContactList(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactListId]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get(`contact-list-items`, {
            params: { searchParam, pageNumber, contactListId },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          const newExcludedContacts = data.contacts.map((contact) => contact.number);
          // console.log("Excluded Contacts on fetch:", newExcludedContacts);
          setExcludedContacts(data.contacts.map(contact => contact.number)); // Collect the Phones of the current contacts
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, contactListId]);

  useEffect(() => {
    // console.log("Excluded Contacts updated:", excludedContacts);
  }, [excludedContacts]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-ContactListItem`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.record });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.id });
      }

      if (data.action === "reload") {
        dispatch({ type: "LOAD_CONTACTS", payload: data.records });
      }
    });

    socket.on(
      `company-${companyId}-ContactListItem-${contactListId}`,
      (data) => {
        if (data.action === "reload") {
          dispatch({ type: "LOAD_CONTACTS", payload: data.records });
        }
      }
    );

    return () => {
      socket.disconnect();
    };
  }, [contactListId]);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactListItemModal = () => {
    setSelectedContactId(null);
    setContactListItemModalOpen(true);
  };

  const handleCloseContactListItemModal = () => {
    setSelectedContactId(null);
    setContactListItemModalOpen(false);
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactListItemModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contact-list-items/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleOpenSelectContactsModal = () => {
    setSelectContactsModalOpen(true);
  };

  const handleCloseSelectContactsModal = () => {
    setSelectContactsModalOpen(false);
  };

  const handleSaveSelectedContacts = async (selectedContacts) => {
    try {
      const { data } = await api.post(`/contact-list-items/add`, {
        contactListId,
        contactIds: selectedContacts,
      });
      // dispatch({ type: "UPDATE_CONTACTS", payload: data }); // Atualiza a lista local com o novo contato
      data.forEach(contact => {
        dispatch({ type: "UPDATE_CONTACTS", payload: contact });
      });
      toast.success("Contatos adicionados com sucesso!");
      setSearchParam(""); // Trigger reload
      setPageNumber(1);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(`Erro ao adicionar contato: ${err.response.data.message}`);
      } else {
        toastError(err);
      }
    }
  };

  const loadAllContacts = async () => {
    const allContacts = [];
    let page = 1;
    let hasMore = true;
    const loadedContactIds = new Set(); // Para evitar duplicações

    while (hasMore) {
      try {
        const { data } = await api.get(`contact-list-items`, {
          params: { searchParam, pageNumber: page, contactListId },
        });

        // Filtra contatos já carregados
        const newContacts = data.contacts.filter(contact => !loadedContactIds.has(contact.id));

        // Adiciona novos contatos ao Set e à lista
        newContacts.forEach(contact => loadedContactIds.add(contact.id));
        allContacts.push(...newContacts);

        hasMore = data.hasMore;
        page++;
      } catch (err) {
        toastError(err);
        hasMore = false; // Para se houver erro
      }
    }

    return allContacts;
  };

  const handleExport = async () => {
    try {
      setLoading(true); // Mostrar estado de carregamento

      const allContacts = await loadAllContacts();

      // Mapeie os contatos para o formato desejado
      const data = allContacts.map(contact => ({
        Nome: contact.name,
        Número: contact.number,
        Email: contact.email,
      }));

      // Crie um novo arquivo de trabalho
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Contatos");

      // Gere o arquivo Excel
      XLSX.writeFile(workbook, "Lista_de_Contatos.xlsx");
    } catch (error) {
      toastError(error); // Handle errors
    } finally {
      setLoading(false); // Ocultar estado de carregamento
    }
  };



  const handleImportContacts = async () => {
    try {
      const formData = new FormData();
      formData.append("file", fileUploadRef.current.files[0]);
      await api.request({
        url: `contact-lists/${contactListId}/upload`,
        method: "POST",
        data: formData,
      });
    } catch (err) {
      toastError(err);
    }
  };

  const handleRemoveSelectedContacts = async (selectedContacts) => {
    // console.log("Removing selected contacts:", selectedContacts);

    try {
      await api.delete(`/contact-list-items`, {
        data: {
          contactListId,
          contactIds: selectedContacts,
        },
      });

      // Remova os contatos excluídos da lista de contatos excluídos
      const updatedExcludedContacts = excludedContacts.filter(
        (id) => !selectedContacts.includes(id)
      );
      // console.log("Updated Excluded Contacts:", updatedExcludedContacts);

      setExcludedContacts(updatedExcludedContacts);
      setSearchParam(""); // Trigger reload
      setPageNumber(1);

      toast.success("Contatos removidos com sucesso!");
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(`Erro ao remover contato: ${err.response.data.message}`);
      } else {
        toastError(err);
      }
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const goToContactLists = () => {
    history.push("/contact-lists");
  };

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#153969",
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <MainContainer className={classes.mainContainer}>
      <ContactListItemModal
        open={contactListItemModalOpen}
        onClose={handleCloseContactListItemModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      ></ContactListItemModal>
      <SelectContactsModal
        open={selectContactsModalOpen}
        onClose={handleCloseSelectContactsModal}
        onSave={handleSaveSelectedContacts}
        existingContacts={excludedContacts} // Pass excludedContacts here
      />
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contactListItems.confirmationModal.deleteTitle")} ${deletingContact.name
            }?`
            : `${i18n.t("contactListItems.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={() =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : handleImportContacts()
        }
      >
        {deletingContact ? (
          `${i18n.t("contactListItems.confirmationModal.deleteMessage")}`
        ) : (
          <>
            {i18n.t("contactListItems.confirmationModal.importMessage")}
            <a href={planilhaExemplo} download="planilha.xlsx">
              Clique aqui para baixar planilha exemplo.
            </a>
          </>
        )}
      </ConfirmationModal>
      <MainHeader>
        <Grid style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }} container>
          <Grid xs={4} sm={3} item>
            <h2 style={{fontSize: "18px", fontWeight: "500", paddingLeft: "10px"}}>{contactList.name} ({contacts.length} Contatos)</h2>
            {/* <MainHeaderButtonsWrapper> */}
          </Grid>
          <Grid xs={7} sm={9} item>
            <Grid container justifyContent="flex-end">
              <Grid xs={5} sm={6} item justifyContent="flex-end">
                <TextField
                  style={{ backgroundColor: "white", borderRadius: "10px", width: "100%" }}
                  fullWidth
                  placeholder={i18n.t("contactListItems.searchPlaceholder")}
                  type="search"
                  value={searchParam}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid >
                <Button
                  // fullWidth
                  // variant="contained"
                  // color="primary"
                  onClick={goToContactLists}
                >
                  <LightTooltip arrow title="Listas" placement="bottom">
                    <FormatListNumberedOutlinedIcon />
                  </LightTooltip>
                  {/* {i18n.t("contactListItems.buttons.lists")} */}
                </Button>
              </Grid>
              <Grid>
                <Button
                  // fullWidth
                  // variant="contained"
                  // color="primary"
                  onClick={() => {
                    fileUploadRef.current.value = null;
                    fileUploadRef.current.click();
                  }}
                >
                  <LightTooltip arrow title="Importar" placement="bottom">
                    <InputOutlinedIcon />
                  </LightTooltip>
                  {/* {i18n.t("contactListItems.buttons.import")} */}
                </Button>
              </Grid>
              <Grid >
                <Button
                  // fullWidth
                  // variant="contained"
                  // color="primary"
                  onClick={handleExport}
                >
                  <LightTooltip arrow title="Exportar" placement="bottom">
                    <CloudDownloadOutlinedIcon />
                  </LightTooltip>
                  {/* {i18n.t("contactListItems.buttons.import")} */}
                </Button>
              </Grid>
              <Grid>
                <Button
                  // fullWidth
                  // variant="contained"
                  // color="primary"
                  onClick={handleOpenSelectContactsModal}
                // onClick={handleOpenContactListItemModal}
                >
                  <LightTooltip arrow title="Lista de Contatos" placement="bottom">
                    <RecentActorsOutlinedIcon />
                  </LightTooltip>
                  {/* {i18n.t("contactListItems.buttons.add")} */}
                </Button>
              </Grid>
              <Grid>
                <Button
                  onClick={handleOpenContactListItemModal}
                >
                  <LightTooltip arrow title="Adicionar Novo Contato" placement="bottom">
                    <AddCircleOutlineIcon />
                  </LightTooltip>

                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <>
          <input
            style={{ display: "none" }}
            id="upload"
            name="file"
            type="file"
            accept=".xls,.xlsx"
            onChange={() => {
              setConfirmOpen(true);
            }}
            ref={fileUploadRef}
          />
        </>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ width: "0%" }}>
                #
              </TableCell>
              <TableCell>{i18n.t("contactListItems.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contactListItems.table.number")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contactListItems.table.email")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contactListItems.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell align="center" style={{ width: "0%" }}>
                    <IconButton>
                      {contact.isWhatsappValid ? (
                        <CheckCircleIcon
                          titleAccess="Whatsapp Válido"
                          htmlColor="green"
                        />
                      ) : (
                        <BlockIcon
                          titleAccess="Whatsapp Inválido"
                          htmlColor="grey"
                        />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell align="center">{contact.number}</TableCell>
                  <TableCell align="center">{contact.email}</TableCell>
                  <TableCell align="center">
                    <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={() => {
                            setConfirmOpen(true);
                            setDeletingContact(contact);
                          }}
                        >
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default ContactListItems;
