import React from "react";

import { Avatar, Card, CardHeader, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import { makeStyles } from "@material-ui/core/styles";
import { grey } from '@material-ui/core/colors';
import shadows from "@material-ui/core/styles/shadows";

const useStyles = makeStyles(theme => ({
    cardAvatar: {
        fontSize: '55px',
        color: grey[500],
        backgroundColor: '#ffffff',
        width: theme.spacing(7),
        height: theme.spacing(7),
        borderRadius: '10px',
        marginLeft: 'auto',
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    cardTitle: {
        flexGrow: 1,
        fontSize: '16px',
        color: "#153969"
    },
    cardSubtitle: {
        flexGrow: 1,
        color: "#153969",
        fontSize: '20px',
        fontWeight: 'bold'
    }
}));

export default function CardCounter(props) {
    const { title, value, loading, icon } = props
    const classes = useStyles();
    return (!loading ?
        <Card style={{ borderRadius: '20px', paddingLeft: 0, paddingRight: 0, paddingTop: 10, paddingBottom: 10 }}>
            <CardHeader
                classes={{ root: classes.cardHeader }}
                avatar={
                    <Avatar className={classes.cardAvatar}>
                        {icon}
                    </Avatar>
                }
                title={
                    <Typography variant="h6" component="h2" className={classes.cardTitle}>
                        {title}
                    </Typography>
                }
                subheader={
                    <Typography variant="subtitle1" component="p" className={classes.cardSubtitle}>
                        {value}
                    </Typography>
                }
            />
        </Card>
        : <Skeleton variant="rect" height={80} />
    )

}