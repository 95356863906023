import React, { useState, useEffect } from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import useHelps from "../../hooks/useHelps";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
	mainPaper: {
		border: "none",
		width: '100%',
		minHeight: '200px',
		overflowY: "scroll",
		backgroundColor: "#DDE3EC",
		...theme.scrollbarStyles,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	accordion: {
		borderRadius: "10px",
		marginBottom: 5,
		transition: "background-color 0.3s ease",
		'&:hover': {
			backgroundColor: "#B1B1B1",
		},
	}
}));

const Helps = () => {
	const classes = useStyles();
	const [records, setRecords] = useState([]);
	const { list } = useHelps()

	useEffect(() => {
		async function fetchData() {
			const helps = await list()
			setRecords(helps)
		}
		fetchData()
	}, [])

	const renderVideo = (record) => {
		const url = `https://www.youtube.com/embed/${record.video}?autoplay=1`;
		return (
			<iframe
				style={{ width: 500, height: 300, borderRadius: "30px" }}
				src={url}
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			></iframe>
		)
	}

	const detectLink = (text) => {
		if (!text) return null;

		const urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlRegex, function (url) {
			return '<a target="_blank" href="' + url + '">' + url + '</a>';
		})
	};

	const renderHelps = () => {
		return <>
			{records.length ? records.map((record, key) => (
				<Accordion key={key} className={classes.accordion} style={{ borderRadius: "10px", marginBottom: 5 }}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading}>{record.title}</Typography>
						{/* <Typography className={classes.secondaryHeading}>{record.description}</Typography> */}
					</AccordionSummary>
					<AccordionDetails>
						<Typography>{renderVideo(record)}</Typography>


						<Typography style={{ padding: "10px 50px", whiteSpace: "pre-wrap" }}>
							<div dangerouslySetInnerHTML={{ __html: detectLink(record?.description) }} />
						</Typography>

						{/* <Typography>
							<div dangerouslySetInnerHTML={{ __html: detectLink(record?.helplink) }} />
						</Typography> */}
					</AccordionDetails>
				</Accordion>
			)) : null}
		</>
	}

	return (
		<MainContainer>
			<MainHeader>
			<h2 style={{fontSize: "18px", fontWeight: "500", paddingLeft: "10px"}}>Central de Ajuda</h2>
				<MainHeaderButtonsWrapper>
				</MainHeaderButtonsWrapper>
			</MainHeader>
			<Paper className={classes.mainPaper} variant="outlined">
				{renderHelps()}
			</Paper>
		</MainContainer>
	);
};

export default Helps;
