import { Box } from '@mui/material';
import React from 'react';


export const Typebot = () => {
    return (

        <Box >
            <iframe style={{
                width: "97%",
                height: "87vh",
                marginTop: "25px",
                marginLeft: "20px",
                borderRadius: "20px"
            }}
                src="https://chat.connectzapper.com.br/" frameborder="0"></iframe>
        </Box>


    );
}