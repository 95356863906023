import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { FaUserAstronaut } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import background from "../../assets/background-login.jpg";
import logo from "../../assets/logo-login.png";
import roboazul from "../../assets/blue_robot.png";
import {
  Stack,
  Container,
  Grid,
  Box,
  InputAdornment,
  Typography,
  Theme,
} from "@mui/material";
import {
  Divider,
  Icon,
  IconButton,
  Step,
  useMediaQuery,
} from "@material-ui/core";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const classes = useStyles();
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Entre 600px e 960px
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.down("md")); // Entre 960px e 1280px
  const matchesLG = useMediaQuery((theme) => theme.breakpoints.down("lg")); // Entre 1280px e 1920px

  const [user, setUser] = useState({ email: "", password: "" });

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <>
      {/* 
      ===================================== DIV'S ANIMADAS DO BACKGROUND =====================================
    */}
      <div class="bg"></div>
      <div class="bg bg2"></div>
      <div class="bg bg3"></div>
      <div class="content"></div>
      {/* 
      ===================================== DIV'S ANIMADAS DO BACKGROUND =====================================
    */}

      <Stack
        direction="row"
        sx={{
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          // mt: 4,
        }}
      >
        <Grid container style={{ marginBottom: 2 }}>
          {matchesSM && matchesMD && matchesLG && (
            <Container
              maxWidth="md"
              xs={12}
              sm={6}
              md={6}
              style={{
                width: "75%",
                height: "100%",
                background: "#fff",
                padding: "20px",
                boxShadow: "#153969 2px 3px 2px",
                // borderTopRightRadius: 20,
                // borderBottomRightRadius: 20,
                borderRadius: 20,
              }}
            >
              {/* 
              ===================================== DIV DA LOGO E lOGIN =====================================
            */}

              <Grid
                style={{
                  width: "100%",
                  height: "100%",
                  background: "#fff",
                  padding: "20px",
                  // borderTopRightRadius: 20,
                  // borderBottomRightRadius: 20,
                  borderRadius: 20,
                }}
              >
                <Stack sx={{ alignItems: "center" }}>
                  <img
                    style={{ margin: "-10px auto", width: "100%" }}
                    src={logo}
                    alt="Logo"
                  />

                  <Stack
                    sx={{
                      alignItems: "center",
                      textAlign: "center",
                      width: "100%",
                      mt: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "50", fontSize: "12px" }}
                    >

                      {/* <div class="boas-vindas">
                    <span class="line"></span>
                    <h4>LOGIN</h4>
                    <span class="line"></span>
                  </div> */}

                      <strong>Bem-vindo(a)!</strong>
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: "13px" }}>
                      Entre com seu <strong>usuário</strong> e{" "}
                      <strong>senha</strong> para acessar o sistema
                    </Typography>
                  </Stack>
                </Stack>

                <form
                  className={classes.form}
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={i18n.t("login.form.email")}
                    size="small"
                    name="email"
                    value={user.email}
                    onChange={handleChangeInput}
                    autoComplete="email"
                    autoFocus
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <FaUserAstronaut />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  />

                  <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={i18n.t("login.form.password")}
                    size="small"
                    type="password"
                    id="password"
                    value={user.password}
                    onChange={handleChangeInput}
                    autoComplete="current-password"
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <AiFillLock />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    size="small"
                  >
                    {i18n.t("login.buttons.submit")}
                  </Button>

                  {/* 
              ===================================== AREA DAS REDES SOCIAIS =====================================
              */}
                  <Grid sx={{ textAlign: "center", alignItems: "center" }}>
                    <IconButton>
                      <Link href="https://wa.me/5587981171704" target="_blank">
                        <WhatsAppIcon color="primary" />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link
                        href="https://www.youtube.com/@mundo_technology"
                        target="_blank"
                      >
                        <YouTubeIcon color="primary" />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link
                        href="https://www.instagram.com/connectzapper/"
                        target="_blank"
                      >
                        <InstagramIcon color="primary" />
                      </Link>
                    </IconButton>
                  </Grid>

                  {/* 
              ===================================== AREA DAS REDES SOCIAIS =====================================
              */}
                </form>
              </Grid>

              {/* 
              ===================================== DIV DA LOGO E LOGIN =====================================
            */}
            </Container>
          )}

          {!matchesSM && (
            <Container
              maxWidth="md"
              sx={{
                width: "60%",
                borderRadius: 5,
                display: "flex",
                boxShadow: "#153969 2px 3px 2px",
                backgroundColor: "#054F81",
                border: "none",
                // display: {sm: "none"}
              }}
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                height: "400px",
                width: "700px",
              }}
            >
              {/* 
              ===================================== DIV DO ROBô =====================================
            */}
              <Grid
                style={{
                  width: "60%",
                  backgroundColor: "#054F81",
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                  alignItems: "center",
                }}
              >
                <img
                  xs={12}
                  sm={6}
                  md={6}
                  style={{
                    marginLeft: "15%",
                    alignItems: "center",
                    width: "80%",
                    justifyContent: "center",
                    display: "flex",
                  }}
                  src={roboazul}
                  alt="Robô"
                />
              </Grid>
              {/* 
              ===================================== DIV DO ROBô =====================================
            */}

              {/* 
              ===================================== DIV DA LOGO E lOGIN =====================================
            */}

              <Grid
                style={{
                  width: "40%",
                  height: "100%",
                  background: "#fff",
                  padding: "20px",
                  // borderTopRightRadius: 20,
                  // borderBottomRightRadius: 20,
                  borderRadius: 20,
                }}
              >
                <Stack sx={{ alignItems: "center" }}>
                  <img
                    style={{ margin: "-10px auto", width: "80%" }}
                    src={logo}
                    alt="Logo"
                  />

                  <Stack
                    sx={{
                      alignItems: "center",
                      textAlign: "center",
                      width: "100%",
                      mt: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "50", fontSize: "12px" }}
                    >

                      {/* <div class="boas-vindas">
                    <span class="line"></span>
                    <h4>LOGIN</h4>
                    <span class="line"></span>
                  </div> */}

                      <strong>Bem-vindo(a)!</strong>
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: "12px", marginTop: '10px' }}>
                      Entre com seu <strong>usuário</strong> e{" "}
                      <strong>senha</strong> para acessar o sistema
                    </Typography>
                  </Stack>
                </Stack>

                <form
                  className={classes.form}
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={i18n.t("login.form.email")}
                    size="small"
                    name="email"
                    value={user.email}
                    onChange={handleChangeInput}
                    autoComplete="email"
                    autoFocus
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <FaUserAstronaut />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  />

                  <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={i18n.t("login.form.password")}
                    size="small"
                    type="password"
                    id="password"
                    value={user.password}
                    onChange={handleChangeInput}
                    autoComplete="current-password"
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <AiFillLock />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    size="small"
                  >
                    {i18n.t("login.buttons.submit")}
                  </Button>

                  {/* <Grid container justify="flex-end" style={{ marginBottom: 2 }}>
                  <Grid item sx={{ textAlign: "center", width: "100%", pb: 2 }}>
                    <Link
                      href="#"
                      variant="body2"
                      component={RouterLink}
                      to="/signup"
                    >
                      {i18n.t("login.buttons.register")}
                    </Link>
                  </Grid>
                </Grid> */}

                  {/* 
              ===================================== AREA DAS REDES SOCIAIS =====================================
              */}
                  <Grid sx={{ textAlign: "center", alignItems: "center", mb: 4 }}>
                    <IconButton>
                      <Link href="https://wa.me/5587981171704" target="_blank">
                        <WhatsAppIcon color="primary" />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link
                        href="https://www.youtube.com/@mundo_technology"
                        target="_blank"
                      >
                        <YouTubeIcon color="primary" />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link
                        href="https://www.instagram.com/connectzapper/"
                        target="_blank"
                      >
                        <InstagramIcon color="primary" />
                      </Link>
                    </IconButton>
                  </Grid>

                  {/* 
              ===================================== AREA DAS REDES SOCIAIS =====================================
              */}
                </form>
              </Grid>

              {/* 
              ===================================== DIV DA LOGO E LOGIN =====================================
            */}
            </Container>
          )}

          {/* 
          ===================================== AREA DO CNPJ =====================================
        */}
          <Grid
            item
            sx={{ textAlign: "center", width: "100%", marginY: "1", mt: 4 }}
          >
            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: "50",
                textShadow: "#153969 2px 3px 2px",
              }}
              variant="body2"
              color="primary"
              align="center"
            >
              {" "}
              ConnectZapper - CNPJ: 28.349.479/0001-95{" "}
            </Typography>
            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: "50",
                textShadow: "#153969 2px 3px 2px",
              }}
              variant="body2"
              color="primary"
              align="center"
            >
              {" "}
              Todos os direitos reservados{" "}
            </Typography>
            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: "50",
                textShadow: "#153969 2px 3px 2px",
              }}
              variant="body2"
              color="primary"
              align="center"
            >
              © 2023 - {new Date().getFullYear()}.
            </Typography>
          </Grid>

          {/* 
          ===================================== AREA DO CNPJ =====================================
        */}
        </Grid>
      </Stack>
    </>
  );
};

export default Login;
