// Página de Contatos
import React, { useState, useEffect, useReducer, useContext } from "react";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SearchIcon from "@material-ui/icons/Search";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import InputIcon from '@material-ui/icons/Input';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import NewTicketModal from "../../components/NewTicketModal";
import { socketConnection } from "../../services/socket";
import { Grid, Tooltip } from "@material-ui/core";

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_CONTACTS": {
      const contacts = action.payload;
      const newContacts = contacts.filter(contact =>
        !state.some(c => c.id === contact.id)
      );
      return [...state, ...newContacts];
    }
    case "UPDATE_CONTACTS": {
      const contact = action.payload;
      const contactIndex = state.findIndex(c => c.id === contact.id);

      if (contactIndex !== -1) {
        return state.map(c =>
          c.id === contact.id ? { ...c, ...contact } : c
        );
      } else {
        return [contact, ...state];
      }
    }
    case "DELETE_CONTACT": {
      const contactId = action.payload;
      return state.filter(c => c.id !== contactId);
    }
    case "RESET": {
      return [];
    }
    default:
      return state;
  }
};


const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    borderRadius: "20px",
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Contacts = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({});
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber },
          });

          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleimportContact = async () => {
    try {
      await api.post("/contacts/import");
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#153969",
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <MainContainer className={classes.mainContainer}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        initialContact={contactTicket}
        onClose={(ticket) => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      ></ContactModal>
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name
            }?`
            : `${i18n.t("contacts.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : handleimportContact()
        }
      >
        {deletingContact
          ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
          : `${i18n.t("contacts.confirmationModal.importMessage")}`}
      </ConfirmationModal>
      <MainHeader>
        <Grid style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }} container>
          <Grid  item>
            <h2 style={{fontSize: "18px", fontWeight: "500", paddingLeft: "10px"}}>Contatos ({contacts.length} Contatos)</h2>
          </Grid>
          {/* <MainHeaderButtonsWrapper > */}
          <Grid xs={7} sm={7} item>
            <Grid container justifyContent="flex-end">
              <Grid xs={5} sm={8} item justifyContent="flex-end">
                <TextField
                  placeholder={i18n.t("contacts.searchPlaceholder")}
                  style={{ backgroundColor: "white", borderRadius: "6px", width: "100%"}}
                  type="search"
                  value={searchParam}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray"}} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={2} sm={1} >
                <Button
                  onClick={(e) => setConfirmOpen(true)}
                >
                  <LightTooltip arrow title="Importar Contatos" placement="bottom">
                    <InputIcon />
                  </LightTooltip>
                </Button>
              </Grid>
              <Grid xs={2} sm={1}>
                <Button
                  // variant="contained"
                  // color="primary"
                  onClick={handleOpenContactModal}
                >
                  <LightTooltip arrow title="Adicionar Contato" placement="bottom">
                    <AddCircleOutlineIcon />
                  </LightTooltip>
                  {/* {i18n.t("contacts.buttons.add")} */}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* </MainHeaderButtonsWrapper> */}
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {/* <TableCell>{"ID"}</TableCell>; */}
              <TableCell padding="checkbox" />

              <TableCell>{i18n.t("contacts.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.whatsapp")}
              </TableCell>
              <TableCell align="center">
                {"Etiquetas"}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.email")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts

                // .sort((a, b) => a.id - b.id) // Ordena os elementos pelo ID
                .map((contact) => (
                  <TableRow key={contact.id}>
                    {/* <TableCell>{contact.id}</TableCell> */}
                    <TableCell style={{ paddingRight: 0 }}>
                      {<Avatar src={contact.profilePicUrl} />}
                    </TableCell>

                    <TableCell>{contact.name}</TableCell>
                    <TableCell align="center">{contact.number}</TableCell>
                    <TableCell align="center" style={{ width: '300px' }}>{
                      contact.tags.map((tag) => (
                        <span
                          key={tag.id}
                          style={{
                            margin: '2px',  // Ajusta a margem para dar espaço entre as tags
                            marginBottom: '4px',  // Adiciona espaço entre as linhas de tags
                            backgroundColor: tag.color,
                            color: "white",
                            padding: '2px 4px',
                            borderRadius: '4px',
                            marginRight: "5px",
                            display: "inline-block",
                          }}
                        >
                          {tag.name}
                        </span>
                      ))
                    }
                    </TableCell>
                    <TableCell align="center">{contact.email}</TableCell>
                    <TableCell align="center" style={{ width: '200px' }}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setContactTicket(contact);
                          setNewTicketModalOpen(true);
                        }}
                      >
                        <WhatsAppIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => hadleEditContact(contact.id)}
                      >
                        <CreateOutlinedIcon />
                      </IconButton>
                      <Can
                        role={user.profile}
                        perform="contacts-page:deleteContact"
                        yes={() => (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              setConfirmOpen(true);
                              setDeletingContact(contact);
                            }}
                          >
                            <DeleteForeverOutlinedIcon />
                          </IconButton>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
