import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import clsx from "clsx";

import { Paper, makeStyles } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInputCustom/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtonsCustom";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TagsContainer } from "../TagsContainer";
import { socketConnection } from "../../services/socket";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    borderRadius: 20,
    marginLeft: 10,
    boxShadow: "0px 2px 4px rgba(0, 0, 2, 0.4)" // Adiciona uma sombra suave
  },

  mainWrapper: {
    backgroundColor: "#DDE3EC",
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: "20px",
    // borderTopLeftRadius: 20,
    // borderBottomLeftRadius: 20,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    // borderTopRightRadius: 20,
    // borderBottomRightRadius: 20,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  // const { user } = useContext(AuthContext);
  const [tagsVisible, setTagsVisible] = useState(false); // Estado para controlar a visibilidade das tags
  const [componentOpen, setComponentOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [tags, setTags] = useState(null);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {

          const ticketIdNew = ticketId ? ticketId : ticket.id;

          if (!ticketIdNew) return;

          const { data } = await api.get("/tickets/u/" + ticketIdNew);



          setContact(data.contact);
          setTicket(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, history]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on("connect", () => socket.emit("joinChatBox", `${ticket.id}`));

    socket.on(`company-${companyId}-ticket`, (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        toast.success("Ticket deleted sucessfully.");
        history.push("/tickets");
      }
    });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, ticket, history]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const toggleTagsVisible = () => {
    setTagsVisible(!tagsVisible); // Alternar a visibilidade das tags
    setComponentOpen(true); // Quando as tags são visíveis, o componente está aberto
  };

  const handleClickOutside = () => {
    setComponentOpen(false); // Se o usuário clicar fora, o componente é fechado
  };

  const renderTicketInfo = () => {
    if (ticket.user !== undefined) {
      return (
        <TicketInfo
          contact={contact}
          ticket={ticket}
          onClick={handleDrawerOpen}
        />
      );
    }
  };

  // const tagAberta = () => {
  //   setTagaberta(!tagAberta);
  // }



  const renderMessagesList = () => {
    return (
      <>
        <MessagesList
          ticket={ticket}
          ticketId={ticket.id}
          isGroup={ticket.isGroup}
        ></MessagesList>
        <MessageInput ticketId={ticket.id} ticketStatus={ticket.status} />
      </>
    );
  };
  const handleTicketUpdateTags = (tagsValue) => {
    setTags(tagsValue)
  }
  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          {renderTicketInfo()}

          {/**
           * 
           * criar uma funcao de abrir e fechar 
           * passa o valor para o componetne  
           * 
           */}

          {/* <TicketActionButtons ticket={ticket} onTicketUpdate={handleTicketUpdateTags} tagAberta={tagAberta} /> */}
          <TicketActionButtons onClick={handleClickOutside} ticket={ticket} onTicketUpdate={handleTicketUpdateTags} toggleTagsVisible={toggleTagsVisible} /> {/* Passa a função de alternância de tags */}
        </TicketHeader>
        <Paper>
          {/* {
            tagAberta &&
            <TagsContainer ticket={ticket} dataTags={tags} />
          } */}
          {/* {tagsVisible && <TagsContainer ticket={ticket} dataTags={tags} />} Renderiza TagsContainer apenas se tagsVisible for verdadeiro */}
          {tagsVisible && componentOpen && (
            <TagsContainer ticket={ticket} dataTags={tags} />
          )}
        </Paper>
        <ReplyMessageProvider>{renderMessagesList()}</ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
        ticket={ticket}
      />
    </div>
  );
};

export default Ticket;
