import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import StarIcon from '@material-ui/icons/Star';
import { Typography } from "@material-ui/core";
import moment from 'moment';
import Rating from '@material-ui/lab/Rating';
import { set } from "date-fns";
import api from "../../services/api";
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
    on: {
        color: "white",
        backgroundColor: "#3DE900",
        fontSize: '22px',
        borderRadius: "20px",
        height: "25%",
        border: "2px solid #3DE900",
    },
    off: {
        color: "white",
        backgroundColor: "#FF0000",
        fontSize: '22px',
        borderRadius: "20px",
        height: "25%",
        border: "2px solid #FF0000",
    },
    pointer: {
        cursor: "pointer"
    },
    titleContainer: {
        position: 'sticky',
        top: 0,
        zIndex: 4,
        backgroundColor: theme.palette.background.paper,
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        padding: theme.spacing(1),
    },
    title: {
        position: 'sticky',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        color: "#153969",
    },
    star: {
        color: '#FFD700',
        marginRight: '8px',
    },
    ratingBox: {
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        zIndex: 1
    },
    tableHead: {
        backgroundColor: theme.palette.background.paper,
        position: 'sticky',
        top: 38,
        zIndex: 4,
    },
    tableBody: {
        overflowY: 'auto',
        flex: 1,
    },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: '20px',
    maxHeight: '270px',
    minHeight: '270px',
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.background.default,
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D9D9D9',
        borderRadius: '4px',
    },
}));



function RatingBox({ rating }) {
    const classes = useStyles();
    const ratingValue = rating === null ? 0 : (rating / 3) * 4; // Calcula o valor das estrelas com base na porcentagem

    return (
        <TableCell className={classes.ratingBox}>
            <Rating
                value={ratingValue}
                max={4}
                precision={0.25} // Permite um quarto de estrela
                readOnly
                className={classes.star}
            />
        </TableCell>
    );
}

export default function TableAttendantsStatus(props) {
    const { loading, attendants, dataini, dataFinal } = props;
    const classes = useStyles();

    const [satisfacaoData, setSatisfacaoData] = useState(null);



    function renderList() {
        return attendants.map((a, k) => (
            <TableRow key={k}>
                <TableCell>{a.name}</TableCell>
                <RatingBox rating={a.rating} />
                {/* <Table>{satisfacaoData.percentagem_boa}</Table> */}
                <TableCell align="center">{formatTime(a.avgSupportTime)}</TableCell>
                <TableCell align="center">
                    {a.online ? (
                        <CheckCircleOutlinedIcon className={classes.on} />
                    ) : (
                        <CancelOutlinedIcon className={classes.off} />
                    )}
                </TableCell>
                {/* <TableCell align="center">{formatTime(a.minutes)}</TableCell> */}
            </TableRow>
        ));
    }


    async function getAssessmentInformations() {
        try {
            const params = {
                initialDate: dataini,
                finalDate: dataFinal
            }

            const { data } = await api.get("/dashboard/assessment-info", { params })

            setSatisfacaoData(data)
        } catch (error) {
            console.log("erro ao buscar informações ", error)
        }
    }



    // async function getAssessmentInformations() {
    //     try {
    //         const params = {
    //             initialDate: dateini,
    //             finalDate: dateFinal
    //         }

    //         const { data } = await api.get("/dashboard/assessment-info", { params })

    //         setSatisfacaoData(data)

    //     } catch (error) {

    //     }
    // }


    function formatTime(minutes) {
        return moment().startOf('day').add(minutes, 'minutes').format('HH[h] mm[m]');
    }

    return (!loading ? (
        <StyledTableContainer component={Paper} >
            <div className={classes.titleContainer}>
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Avaliações
                </Typography>
            </div>
            <Table>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell align="center">Satisfação</TableCell>
                        <TableCell align="center">T.M. de Atendimento</TableCell>
                        <TableCell align="center">Status</TableCell>
                        {/* <TableCell align="center">T. de Atividade</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {renderList()}
                </TableBody>
            </Table>
        </StyledTableContainer>
    ) : (
        <Skeleton variant="rect" height={150} />
    ));
}
