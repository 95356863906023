import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Title from "../Title";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import useSettings from "../../hooks/useSettings";
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import { Tabs, Tab } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
    },
    tab: {
        background: "#f2f5f3",
        borderRadius: 4,
        width: "100%",
        "& .MuiTab-wrapper": {
            color: "#128c7e"
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "center"
        }
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
        width: "100%",
    },
    cardAvatar: {
        fontSize: "55px",
        color: grey[500],
        backgroundColor: "#ffffff",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: "18px",
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: "14px",
    },
    alignRight: {
        textAlign: "right",
    },
    fullWidth: {
        width: "100%",
    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
    },
}));

export default function IntegrationManager(props) {
    const { settings, scheduleTypeChanged } = props;
    const classes = useStyles();
    const [userRating, setUserRating] = useState("disabled");
    const [scheduleType, setScheduleType] = useState("disabled");
    const [callType, setCallType] = useState("enabled");
    const [openTicketsCampaigns, setOpenTicketsCampaigns] = useState('disabled');
    const [notificationSound, setNotificationSound] = useState("disabled");
    const [chatbotType, setChatbotType] = useState("");
    const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("enabled");

    const [loadingUserRating, setLoadingUserRating] = useState(false);
    const [loadingScheduleType, setLoadingScheduleType] = useState(false);
    const [loadingCallType, setLoadingCallType] = useState(false);
    const [loadingOpenTicketsCampaigns, setLoadingOpenTicketsCampaigns] = useState(false);
    const [loadingChatbotType, setLoadingChatbotType] = useState(false);
    const [loadingCheckMsgIsGroup, setCheckMsgIsGroup] = useState(false);


    const [ipixcType, setIpIxcType] = useState("");
    const [loadingIpIxcType, setLoadingIpIxcType] = useState(false);
    const [tokenixcType, setTokenIxcType] = useState("");
    const [loadingTokenIxcType, setLoadingTokenIxcType] = useState(false);

    const [ipmkauthType, setIpMkauthType] = useState("");
    const [loadingIpMkauthType, setLoadingIpMkauthType] = useState(false);
    const [clientidmkauthType, setClientIdMkauthType] = useState("");
    const [loadingClientIdMkauthType, setLoadingClientIdMkauthType] = useState(false);
    const [clientsecretmkauthType, setClientSecrectMkauthType] = useState("");
    const [loadingClientSecrectMkauthType, setLoadingClientSecrectMkauthType] = useState(false);

    const [asaasType, setAsaasType] = useState("");
    const [loadingAsaasType, setLoadingAsaasType] = useState(false);

    const { update } = useSettings();

    useEffect(() => {
        if (Array.isArray(settings) && settings.length) {
            const userRating = settings.find((s) => s.key === "userRating");
            if (userRating) {
                setUserRating(userRating.value);
            }
            const scheduleType = settings.find((s) => s.key === "scheduleType");
            if (scheduleType) {
                setScheduleType(scheduleType.value);
            }
            const callType = settings.find((s) => s.key === "call");

            if (callType) {
                setCallType(callType.value);
            }

            const openTicketsCampaigns = settings.find((s) => s.key === "openTicketsCampaigns");

            if (openTicketsCampaigns) {
                setOpenTicketsCampaigns(openTicketsCampaigns.value);
            }

            const soundNotification = settings.find((s) => s.key === "notificationSound");

            if (soundNotification) {
                setNotificationSound(soundNotification.value);
            }

            const CheckMsgIsGroup = settings.find((s) => s.key === "CheckMsgIsGroup");
            if (CheckMsgIsGroup) {
                setCheckMsgIsGroupType(CheckMsgIsGroup.value);
            }
            const chatbotType = settings.find((s) => s.key === "chatBotType");
            if (chatbotType) {
                setChatbotType(chatbotType.value);
            }

            const ipixcType = settings.find((s) => s.key === "ipixc");
            if (ipixcType) {
                setIpIxcType(ipixcType.value);
            }

            const tokenixcType = settings.find((s) => s.key === "tokenixc");
            if (tokenixcType) {
                setTokenIxcType(tokenixcType.value);
            }

            const ipmkauthType = settings.find((s) => s.key === "ipmkauth");
            if (ipmkauthType) {
                setIpMkauthType(ipmkauthType.value);
            }

            const clientidmkauthType = settings.find((s) => s.key === "clientidmkauth");
            if (clientidmkauthType) {
                setClientIdMkauthType(clientidmkauthType.value);
            }

            const clientsecretmkauthType = settings.find((s) => s.key === "clientsecretmkauth");
            if (clientsecretmkauthType) {
                setClientSecrectMkauthType(clientsecretmkauthType.value);
            }

            const asaasType = settings.find((s) => s.key === "asaas");
            if (asaasType) {
                setAsaasType(asaasType.value);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    async function handleChangeUserRating(value) {
        setUserRating(value);
        setLoadingUserRating(true);
        await update({
            key: "userRating",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingUserRating(false);
    }

    async function handleScheduleType(value) {
        setScheduleType(value);
        setLoadingScheduleType(true);
        await update({
            key: "scheduleType",
            value,
        });
        //toast.success("Oraçãpeo atualizada com sucesso.");
        toast.success('Operação atualizada com sucesso.', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "light",
        });
        setLoadingScheduleType(false);
        if (typeof scheduleTypeChanged === "function") {
            scheduleTypeChanged(value);
        }
    }

    async function handleCallType(value) {
        setCallType(value);
        setLoadingCallType(true);
        await update({
            key: "call",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingCallType(false);
    }

    async function handleOpenTicketsCampaigns(value) {

        setOpenTicketsCampaigns(value);
        setLoadingOpenTicketsCampaigns(true);
        await update({
            key: "openTicketsCampaigns",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingOpenTicketsCampaigns(false);

    }

    async function handleChatbotType(value) {
        setChatbotType(value);
        setLoadingChatbotType(true);
        await update({
            key: "chatBotType",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingChatbotType(false);
    }

    async function handleGroupType(value) {
        setCheckMsgIsGroupType(value);
        setCheckMsgIsGroup(true);
        await update({
            key: "CheckMsgIsGroup",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setCheckMsgIsGroup(false);
        /*     if (typeof scheduleTypeChanged === "function") {
              scheduleTypeChanged(value);
            } */
    }

    async function handleChangeIPIxc(value) {
        setIpIxcType(value);
        setLoadingIpIxcType(true);
        await update({
            key: "ipixc",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingIpIxcType(false);
    }

    async function handleSoundNotification(value) {
        setNotificationSound(value);
        setLoadingCallType(true);
        await update({
            key: "notificationSound",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingCallType(false);
    }

    async function handleChangeTokenIxc(value) {
        setTokenIxcType(value);
        setLoadingTokenIxcType(true);
        await update({
            key: "tokenixc",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingTokenIxcType(false);
    }

    async function handleChangeIpMkauth(value) {
        setIpMkauthType(value);
        setLoadingIpMkauthType(true);
        await update({
            key: "ipmkauth",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingIpMkauthType(false);
    }

    async function handleChangeClientIdMkauth(value) {
        setClientIdMkauthType(value);
        setLoadingClientIdMkauthType(true);
        await update({
            key: "clientidmkauth",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingClientIdMkauthType(false);
    }

    async function handleChangeClientSecrectMkauth(value) {
        setClientSecrectMkauthType(value);
        setLoadingClientSecrectMkauthType(true);
        await update({
            key: "clientsecretmkauth",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingClientSecrectMkauthType(false);
    }

    async function handleChangeAsaas(value) {
        setAsaasType(value);
        setLoadingAsaasType(true);
        await update({
            key: "asaas",
            value,
        });
        toast.success("Operação atualizada com sucesso.");
        setLoadingAsaasType(false);
    }
    return (
        <>

            <Grid spacing={3} container>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                    style={{
                        marginBottom: 20,
                        marginTop: 20
                    }}
                >
                    <Tab

                        label="INTEGRAÇÕES" />

                </Tabs>

            </Grid>
            {/*-----------------IXC-----------------*/}
            <Grid spacing={3} container
                style={{ marginBottom: 10 }}>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                >
                    <Tab

                        label="IXC" />

                </Tabs>
                <Grid xs={12} sm={6} md={6} item>
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="ipixc"
                            name="ipixc"
                            margin="dense"
                            label="IP do IXC"
                            variant="outlined"
                            value={ipixcType}
                            onChange={async (e) => {
                                handleChangeIPIxc(e.target.value);
                            }}
                        >
                        </TextField>
                        <FormHelperText>
                            {loadingIpIxcType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={6} md={6} item>
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="tokenixc"
                            name="tokenixc"
                            margin="dense"
                            label="Token do IXC"
                            variant="outlined"
                            value={tokenixcType}
                            onChange={async (e) => {
                                handleChangeTokenIxc(e.target.value);
                            }}
                        >
                        </TextField>
                        <FormHelperText>
                            {loadingTokenIxcType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            {/*-----------------MK-AUTH-----------------*/}
            <Grid spacing={3} container
                style={{ marginBottom: 10 }}>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                >
                    <Tab label="MK-AUTH" />

                </Tabs>
                <Grid xs={12} sm={12} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="ipmkauth"
                            name="ipmkauth"
                            margin="dense"
                            label="Ip Mk-Auth"
                            variant="outlined"
                            value={ipmkauthType}
                            onChange={async (e) => {
                                handleChangeIpMkauth(e.target.value);
                            }}
                        >
                        </TextField>
                        <FormHelperText>
                            {loadingIpMkauthType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="clientidmkauth"
                            name="clientidmkauth"
                            margin="dense"
                            label="Client Id"
                            variant="outlined"
                            value={clientidmkauthType}
                            onChange={async (e) => {
                                handleChangeClientIdMkauth(e.target.value);
                            }}
                        >
                        </TextField>
                        <FormHelperText>
                            {loadingClientIdMkauthType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="clientsecretmkauth"
                            name="clientsecretmkauth"
                            margin="dense"
                            label="Client Secret"
                            variant="outlined"
                            value={clientsecretmkauthType}
                            onChange={async (e) => {
                                handleChangeClientSecrectMkauth(e.target.value);
                            }}
                        >
                        </TextField>
                        <FormHelperText>
                            {loadingClientSecrectMkauthType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            {/*-----------------ASAAS-----------------*/}
            <Grid spacing={3} container
                style={{ marginBottom: 10 }}>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    className={classes.tab}
                >
                    <Tab label="ASAAS" />

                </Tabs>
                <Grid xs={12} sm={12} md={12} item>
                    <FormControl className={classes.selectContainer}>
                        <TextField
                            id="asaas"
                            name="asaas"
                            margin="dense"
                            label="Token Asaas"
                            variant="outlined"
                            value={asaasType}
                            onChange={async (e) => {
                                handleChangeAsaas(e.target.value);
                            }}
                        >
                        </TextField>
                        <FormHelperText>
                            {loadingAsaasType && "Atualizando..."}
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}
