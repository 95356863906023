// Arquivo de Layout do Connect, onde está sendo importado os componentes base da aplicação

import React, { useState, useContext, useEffect, useCallback } from "react";
import clsx from "clsx";

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  useTheme,
  useMediaQuery,
  Button,
  Paper,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import toastError from "../errors/toastError";
import AnnouncementsPopover from "../components/AnnouncementsPopover";
import { socketConnection } from "../services/socket";
import ChatPopover from "../pages/Chat/ChatPopover";
import MenuLateral from "../components/MenuLateral";
import "./style.css";

import Logo from "../assets/Logo_connect_of.png"

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    fontSize: 12,
    overflow: "hidden",
  },

  toolbar: {
    zIndex: 0, // Defina um z-index maior para o Toolbar
    transition: "all .3s ease"
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "48px",
  },
  appBar: {
    zIndex: 100,
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)", // Adicione esta linha para remover a sombra
    width: "100%",
    paddingLeft: "30px",
    backgroundColor: "#DDE3EC",
  },
  appBarShift: {
    width: "100%",
    boxShadow: "none", // Adicione esta linha para remover a sombra
    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)", // Adicione esta linha para remover a sombra
    
  },
  menuButton: {
    marginRight: 36,
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
  },
  drawerPaper: {
    backgroundColor: "#153969",
    position: "relative",
    whiteSpace: "nowrap",
    transition: "all .3s ease",
    width: drawerWidth,
    overflowX: "hidden",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: "all .3s ease",
    width: "55px",
    overflowX: "hidden",
  },
  appBarSpacer: {
    minHeight: "48px",
    overflowX: "hidden",
  },
  content: {
    flex: 1,
    overflow: "auto",
    ...theme.scrollbarStyles,
    overflowX: "hidden",
  },
  container: {
    overflowX: "hidden",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    overflowX: "hidden",
  },
  containerWithScroll: {
    width: "100%",
    paddingTop: 10,
    paddingBottom: 1,
    flex: 1,
    padding: "10px",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    overflowX: "hidden",
    color: "#153969",
    backgroundColor: "transparent",
  },
  versionText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px", // Tamanho da fonte
    color: "#fff", // Cor do texto
    textAlign: "center", // Centraliza o texto dentro do div
    backgroundColor: "#153969",
  },
}));

const LoggedInLayout = ({ children }) => {
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Entre 600px e 960px
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [testeMenuOpen, setTesteMenuOpen] = useState(false)
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext);

  const theme = useTheme();
  const greaterThenSm = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (document.body.offsetWidth > 600) {
      setDrawerOpen(true);
    }
  }, []);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");

    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-auth`, (data) => {
      if (data.user.id === +userId) {
        toastError("Sua conta foi acessada em outro computador.");
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
    });

    socket.emit("userStatus");
    const interval = setInterval(() => {
      socket.emit("userStatus");
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };

  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };

  if (loading) {
    return <BackdropLoading />;
  }

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#153969",
      color: "white",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <div className={classes.root}>
      <MenuLateral />
      {/* <Drawer
        // ref={drawerRef} // Adicione a referência ao elemento do Drawer
        style={{
          backgroundColor: "#153969",
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
        }}
        variant={drawerVariant}
        // className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
        value={{drawerOpen, setDrawerOpen}}
      >
        <div
          className={classes.toolbarIcon}
          style={{
            backgroundColor: "#153969",
            marginTop: 6,
            marginRight: 16,
            marginLeft: 8,
            paddingRight: 14,
            paddingLeft: 14,
            marginBottom: -8,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          {drawerOpen ? (
            <img
              src={logo}
              style={{
                width: "150px",
                margin: "15px 10px",
              }}
              alt="logo"
            />
          ) : (
            <img
              src={logoClosed}
              style={{
                width: "32px",
                margin: "21px 0px",
              }}
              alt="logo"
            />
          )}
        </div>
        <List className={classes.containerWithScroll}>
          <MainListItems drawerClose={drawerClose} isOpen={drawerOpen} />
        </List>
      </Drawer> */}
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        // style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
      >
        <Toolbar
          variant="dense"
          className={classes.toolbar}
          md={6}
          style={{
            height: "7%",
            // backgroundColor: "#DDE3EC",
            color: "#343434",
            marginLeft: drawerOpen ? 10 : 100,
            marginRight: matchesSM ? 0 : 0, // Define marginRight como 0 quando matchesMD for verdadeiro
          }}
        >
          
            <img src={Logo} alt="Logo_connect" style={{width: "120px", marginRight: "24px"}}/>
          
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {greaterThenSm ? (
              <>
                Olá <b>{user.name}</b>, Seja bem-vindo.
              </>
            ) : (
              user.name
            )}
          </Typography>

          {user.id && <NotificationsPopOver />}
          <AnnouncementsPopover />
          <ChatPopover />

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              variant="contained"
              style={{ color: "#343434" }}
            >
              <LightTooltip title="Perfil" placement="bottom">
                <AccountCircle />
              </LightTooltip>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t("mainDrawer.appBar.user.profile")}
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                {i18n.t("mainDrawer.appBar.user.logout")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;
