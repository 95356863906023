import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
	mainContainer: {
		maxWidth: "100%",
		flex: 1,
		padding: "8px 10px",
		height: `calc(100% - 48px)`
	},

	contentWrapper: {
		height: "100%",
		overflowY: "hidden",
		display: "flex",
		flexDirection: "column",
		backgroundColor: "#DDE3EC",
		borderRadius: "10px",
		padding: "10px",
		boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)" // Adiciona uma sombra suave
	},
}));

const MainContainer = ({ children }) => {
	const classes = useStyles();

	return (
		<Container className={classes.mainContainer}>
			<div className={classes.contentWrapper}>{children}</div>
		</Container>
	);
};

export default MainContainer;
