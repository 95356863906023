import React, { useState, useEffect, useContext, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment"
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize, head } from "lodash";
import { Grid, IconButton } from "@mui/material";
import { set } from "date-fns";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const ScheduleSchema = Yup.object().shape({
	body: Yup.string()
		.min(5, "Mensagem muito curta")
		.required("Obrigatório"),
	contactId: Yup.number().required("Obrigatório"),
	sendAt: Yup.string().required("Obrigatório")
});

const ScheduleModal = ({ open, onClose, scheduleId, contactId, cleanContact, reload }) => {
	const classes = useStyles();
	const history = useHistory();
	const { user } = useContext(AuthContext);

	const initialState = {
		body: "",
		contactId: "",
		sendAt: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm'),
		sentAt: "",
		attachment: null,
		recurrenceBool: false,
		tempo: "days",
		interval: 1,
		repeat: 1,
		diaUtilOption: "none",
		signMessage: false,
	};

	const initialContact = {
		id: "",
		name: ""
	}

	const [schedule, setSchedule] = useState(initialState);
	const [currentContact, setCurrentContact] = useState(initialContact);
	const [contacts, setContacts] = useState([initialContact]);
	const attachmentRef = useRef(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (contactId && contacts.length) {
			const contact = contacts.find(c => c.id === contactId);
			if (contact) {
				setCurrentContact(contact);
			}
		}
	}, [contactId, contacts]);

	useEffect(() => {
		if (open) {
			showSchedule();
		}
	}, [scheduleId, contactId, open, user]);

	const handleClose = () => {
		onClose();
		setSchedule(initialState);
	};

	const showSchedule = async () => {
		try {
			const { companyId } = user;
			const { data: contactList } = await api.get('/contacts/list', { params: { companyId: companyId } });
			let customList = contactList.map((c) => ({ id: c.id, name: c.name }));

			if (isArray(customList)) {
				setContacts([{ id: "", name: "" }, ...customList]);
			}
			if (contactId) {
				setSchedule(prevState => {
					return { ...prevState, contactId }
				});
			}

			if (!scheduleId) return;

			const { data } = await api.get(`/schedules/${scheduleId}`);
			setSchedule(prevState => {
				let tempo = data.recurrence != null ? JSON.parse(data.recurrence)[0].tempo : "days";
				let repeat = data.recurrence != null ? JSON.parse(data.recurrence)[0].repeat : 1;
				let interval = data.recurrence != null ? JSON.parse(data.recurrence)[0].interval : 1;
				let diaUtilOption = data.recurrence != null && JSON.parse(data.recurrence)[0].diaUtilOption ? JSON.parse(data.recurrence)[0].diaUtilOption : "none";

				return {
					...prevState,
					...data,
					sendAt: moment(data.sendAt).format('YYYY-MM-DDTHH:mm'),
					attachment: data.mediaName ? { name: data.mediaName } : null,
					recurrenceBool: data.recurrence != null,
					tempo,
					interval,
					repeat,
					diaUtilOption
				};
			});
			setCurrentContact(data.contact);
		} catch (error) {
			toastError(error);
		}
	}

	const handleSaveSchedule = async values => {

		console.log(values);


		const scheduleData = { ...values, userId: user.id };

		try {
			if (scheduleId) {
				await api.put(`/schedules/${scheduleId}`, scheduleData);

				if (values.attachment && values.attachment.size) {

					const formData = new FormData();
					formData.append("file", values.attachment);

					await api.post(`/schedules/${scheduleId}/media-upload`, formData);

				}

			} else {

				const { data } = await api.post("/schedules", scheduleData);

				if (values.attachment) {

					const formData = new FormData();
					formData.append("file", values.attachment);

					await api.post(`/schedules/${data.id}/media-upload`, formData);

				}
			}
			toast.success(i18n.t("scheduleModal.success"));
			if (typeof reload == 'function') {
				reload();
			}
			if (contactId) {
				if (typeof cleanContact === 'function') {
					cleanContact();
					history.push('/schedules');
				}
			}
		} catch (err) {
			toastError(err);
		}
		setCurrentContact(initialContact);
		setSchedule(initialState);
		handleClose();
	};

	const handleAttachmentFile = (e, setFieldValue) => {

		const file = head(e.target.files);

		if (file) {
			setFieldValue('attachment', file);
		}

	};

	const removeMedia = async () => {

		await api.delete(`/schedule/${scheduleId}/media-upload`);


	}


	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{schedule.status === 'ERRO' ? 'Erro de Envio' : `Mensagem ${capitalize(schedule.status)}`}
				</DialogTitle>
				<Formik
					initialValues={schedule}
					enableReinitialize={true}
					validationSchema={ScheduleSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveSchedule(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values, setFieldValue }) => (
						<Form>
							<DialogContent dividers>

								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("scheduleModal.form.sendAt")}
										type="datetime-local"
										name="sendAt"
										InputLabelProps={{
											shrink: true,
										}}
										error={touched.sendAt && Boolean(errors.sendAt)}
										helperText={touched.sendAt && errors.sendAt}
										variant="outlined"
										fullWidth
									/>
								</div>

								<br />

								<div className={classes.multFieldLine}>
									<FormControl
										variant="outlined"
										fullWidth
									>
										<Autocomplete
											fullWidth
											value={currentContact}
											options={contacts}
											onChange={(e, contact) => {
												const contactId = contact ? contact.id : '';
												setSchedule({ ...schedule, contactId });
												setCurrentContact(contact ? contact : initialContact);
											}}
											getOptionLabel={(option) => option.name}
											getOptionSelected={(option, value) => {
												return value.id === option.id
											}}
											renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Contato" />}
										/>
									</FormControl>

								</div>

								<br />

								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										rows={9}
										multiline={true}
										label={i18n.t("scheduleModal.form.body")}
										name="body"
										error={touched.body && Boolean(errors.body)}
										helperText={touched.body && errors.body}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>

								<br />

								<div className={classes.multFieldLine}>
									<FormControl variant="outlined" fullWidth>
										<InputLabel id="demo-simple-select-label">Recorrência</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={values.recurrenceBool}
											label="Recorrência"
											onChange={e => setFieldValue('recurrenceBool', e.target.value)}
										>
											<MenuItem value={false}>Desativado</MenuItem>
											<MenuItem value={true}>Ativado</MenuItem>
										</Select>
									</FormControl>

								</div>

								<br />

								{values.recurrenceBool && (
									<div className={classes.multFieldLine}>


										<div style={{ display: "flex", marginBottom: "10px", gap: '10px' }}>


											<FormControl variant="outlined" fullWidth>
												<InputLabel >Tipo de recorrencia</InputLabel>
												<Select
													value={values.tempo}
													label="Tipo de recorrencia"
													onChange={e => setFieldValue('tempo', e.target.value)}
												>
													<MenuItem value="days">Diário</MenuItem>
													<MenuItem value="weeks">Semanal</MenuItem>
													<MenuItem value="months">Mensal</MenuItem>
												</Select>
											</FormControl>

											<TextField
												inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: '1' }}
												type="number"
												min="1"
												label="Intervalo"
												value={values.interval}
												onChange={e => setFieldValue('interval', e.target.value)}
												variant="outlined" />

											<TextField
												inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: '1' }}
												min="1"
												type="number"
												label="Repetir"
												value={values.repeat}
												onChange={e => setFieldValue('repeat', e.target.value)}
												variant="outlined" />


										</div>


									</div>
								)}

								<br />

								{values.attachment !== null ?

									<Grid item>
										<Button style={{ cursor: 'inherit' }} startIcon={<AttachFileIcon />}>
											{values.attachment != null && values.attachment?.name}
										</Button>

										{values.attachment && (
											<IconButton
												onClick={async () => {
													setFieldValue("attachment", null);
													removeMedia();

													// setTimeout(() => {
													// 	reloadQueues();
													// }, 300);


												}}
												color="secondary"
											>
												<DeleteOutlineIcon />
											</IconButton>
										)}
									</Grid>

									:

									<Grid item >

										<Button
											color="primary"
											onClick={() => attachmentRef.current.click()}
											disabled={loading}
											variant="outlined"
										>
											{i18n.t("campaigns.dialog.buttons.attach")}
										</Button>
									</Grid>

								}



								<div style={{ display: "none" }}>
									<input
										type="file"
										ref={attachmentRef}
										onChange={(e) => handleAttachmentFile(e, setFieldValue)}
									/>
								</div>

								<br />

							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									// color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("scheduleModal.buttons.cancel")}
								</Button>
								{(schedule.sentAt === null || schedule.sentAt === "") && (
									<Button
										type="submit"
										// color="primary"
										style={{ backgroundColor: "#1A4783", color: "white" }}
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
									>
										{scheduleId
											? `${i18n.t("scheduleModal.buttons.okEdit")}`
											: `${i18n.t("scheduleModal.buttons.okAdd")}`}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								)}
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ScheduleModal;