// Você está no componente Menu Lateral, onde tem toda configuração. Lembrando que o arquivo de estilo é o CSS na mesma pasta desse arquivo
import React, { useState, useContext, useRef, useEffect } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Link } from "react-router-dom";
import "./MenuLateral.css";
import {
  BiMenu,
  BiMenuAltLeft,
  BiLineChart,
  BiHelpCircle,
  BiChat,
  BiTask,
  BiFilterAlt,
  BiCalendar,
} from "react-icons/bi";
import { TbHeadset } from "react-icons/tb";
import { TiFlashOutline } from "react-icons/ti";
import {
  LuContact2,
  LuTags,
  LuBuilding2,
  LuCalendarPlus,
  LuCalendarCheck,
  LuUsers,
  LuArrowRightLeft,
  LuAlignHorizontalDistributeCenter,
  LuUserCog2,
  LuServer,
  LuSettings,
} from "react-icons/lu";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { GoTools } from "react-icons/go";
import { TiFlowChildren } from "react-icons/ti";
import { MdOutlineAttachMoney } from "react-icons/md";
import { Tooltip, withStyles } from "@material-ui/core";

export default function MenuLateral() {
  //   Função Menu
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  //   Função DropDown Admin
  const [menuOpenAdmin, setMenuOpenAdmin] = useState(false);
  const handleClickAdmin = () => {
    setMenuOpenAdmin(!menuOpenAdmin);
  };
  // Função DropDown Campanhas
  const [dropCampanhas, setDropCampanhas] = useState(false);
  const handleDropCampanhas = () => {
    setDropCampanhas(!dropCampanhas);
  };
  //   Usuário Logado
  const { user } = useContext(AuthContext); // Obtendo o usuário logado

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#153969",
      color: "white",
      border: "none",
    },
    arrow: {
      border: "none",
      color: "#1A4783", // Cor da seta
    },
  }))(Tooltip);
  return (
    <nav className="container_nav">
      <div className="container_btn_menu">
        <button onClick={handleMenu} id="btn_menu" className="btn_menu">
          {menuOpen ? (
            <BiMenu className="icon_btn_menu" />
          ) : (
            <BiMenuAltLeft className="icon_btn_menu" />
          )}
        </button>
      </div>

      <ul
        className="container_link_menu"
        style={{ width: menuOpen ? "200px" : "60px" }}
      >
        {user.profile === "admin" && ( // Links exclusivos para admin
          <LightTooltip
            title="Dashboard"
            disableHoverListener={menuOpen}
            placement="right-start"
            arrow
          >
            <li
              className="links"
              style={{ padding: menuOpen ? "0px 12px" : "8px 12px" }}
            >
              <Link className="link_nav" to="/">
                <BiLineChart className="icons_nav" />
                <p
                  className="nome_link"
                  style={{ display: menuOpen ? "block" : "none" }}
                >
                  Dashboard
                </p>
              </Link>
            </li>
          </LightTooltip>
        )}
        {/* Links visíveis para todos os perfis */}
        <LightTooltip
          title="Atendimentos"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{ padding: menuOpen ? "0px 12px" : "8px 12px" }}
          >
            <Link className="link_nav" to="/tickets">
              <TbHeadset className="icons_nav" />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Atendimentos
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="Respostas Rápidas"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{ padding: menuOpen ? "0px 12px" : "8px 12px" }}
          >
            <Link className="link_nav" to="/quick-messages">
              <TiFlashOutline className="icons_nav" />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Respostas Rápidas
              </p>
            </Link>
          </li>
        </LightTooltip>

        <LightTooltip
          title="Contatos"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{ padding: menuOpen ? "0px 12px" : "8px 12px" }}
          >
            <Link className="link_nav" to="/contacts">
              <LuContact2 className="icons_nav" />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Contatos
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="Agendamentos"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{ padding: menuOpen ? "0px 12px" : "8px 12px" }}
          >
            <Link className="link_nav" to="/schedules">
              <BiCalendar className="icons_nav" />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Agendamentos
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="Etiquetas"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{ padding: menuOpen ? "0px 12px" : "8px 12px" }}
          >
            <Link className="link_nav" to="/tags">
              <LuTags className="icons_nav" />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Etiquetas
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="CRM"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{ padding: menuOpen ? "0px 12px" : "8px 12px" }}
          >
            <Link className="link_nav" to="/crm">
              <BiFilterAlt className="icons_nav" />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                CRM
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="Tarefas"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{ padding: menuOpen ? "0px 12px" : "8px 12px" }}
          >
            <Link className="link_nav" to="/tasks">
              <BiTask className="icons_nav" />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Tarefas
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="Chat Interno"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{ padding: menuOpen ? "0px 12px" : "8px 12px" }}
          >
            <Link className="link_nav" to="/chats">
              <BiChat className="icons_nav" />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Chat Interno
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="Ajuda"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{ padding: menuOpen ? "0px 12px" : "8px 12px" }}
          >
            <Link className="link_nav" to="/helps">
              <BiHelpCircle className="icons_nav" />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Ajuda
              </p>
            </Link>
          </li>
        </LightTooltip>
        {/* Links Para Usuários ADMIN */}

        {user.profile === "admin" && ( // Links exclusivos para admin
          <LightTooltip
            title="Administração"
            disableHoverListener={menuOpen}
            placement="right-start"
            arrow
          >
            <li
              onClick={handleClickAdmin}
              className="links"
              style={{
                padding: menuOpen ? "0px 12px" : "8px 12px",
                backgroundColor: menuOpenAdmin ? "#0055c5" : "",
              }}
            >
              <Link className="link_nav admin" to="#">
                <div className="container_link_adm">
                  <LuBuilding2 className="icons_nav" />
                  <p
                    className="nome_link"
                    style={{ display: menuOpen ? "block" : "none" }}
                  >
                    Administração
                  </p>
                </div>
                {menuOpenAdmin ? (
                  <IoIosArrowDown className="icons_nav_arrow" />
                ) : (
                  <IoIosArrowForward className="icons_nav_arrow" />
                )}
              </Link>
            </li>
          </LightTooltip>
        )}

        {/* Drop Campanhas */}
        <ul
          className="container_link_menu_admin"
          style={{
            display: menuOpenAdmin ? "block" : "none",
            marginTop: "10px",
          }}
        >
          <LightTooltip
          title="Campanhas"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
          >
            <li
              onClick={handleDropCampanhas}
              className="links"
              style={{ padding: menuOpen ? "0px 12px" : "8px 12px" }}
            >
              <Link className="link_nav admin" to="#">
                <div className="container_link_adm">
                  <LuCalendarPlus className="icons_nav" />
                  <p
                    className="nome_link"
                    style={{ display: menuOpen ? "block" : "none" }}
                  >
                    Campanhas
                  </p>
                </div>
                {dropCampanhas ? (
                  <IoIosArrowDown className="icons_nav_arrow" />
                ) : (
                  <IoIosArrowForward className="icons_nav_arrow" />
                )}
              </Link>
            </li>
          </LightTooltip>
          <div
            className="container_link_menu_admin"
            style={{ display: menuOpenAdmin ? "block" : "none" }}
          >
            <LightTooltip
              title="Criar Campanha"
              disableHoverListener={menuOpen}
              placement="right-start"
              arrow
            >
              <li
                className="links"
                style={{
                  padding: menuOpen ? "0px 12px" : "8px 12px",
                  display: dropCampanhas ? "block" : "none",
                }}
              >
                <Link className="link_nav" to="/campaigns">
                  <LuCalendarCheck className="icons_nav" />
                  <p
                    className="nome_link"
                    style={{ display: menuOpen ? "block" : "none" }}
                  >
                    Criar Campanha
                  </p>
                </Link>
              </li>
            </LightTooltip>
            <LightTooltip
              title="Lista de Contatos"
              disableHoverListener={menuOpen}
              placement="right-start"
              arrow
            >
              <li
                className="links"
                style={{
                  padding: menuOpen ? "0px 12px" : "8px 12px",
                  display: dropCampanhas ? "block" : "none",
                }}
              >
                <Link className="link_nav" to="/contact-lists">
                  <LuUsers className="icons_nav" />
                  <p
                    className="nome_link"
                    style={{ display: menuOpen ? "block" : "none" }}
                  >
                    Lista de Contatos
                  </p>
                </Link>
              </li>
            </LightTooltip>
            {/* <li
              className="links"
              style={{
                padding: menuOpen ? "0px 12px" : "8px 12px",
                display: dropCampanhas ? "block" : "none",
              }}
            >
              <Link className="link_nav" to="/campaigns-config">
                <GoTools className="icons_nav" />
                <p
                  className="nome_link"
                  style={{ display: menuOpen ? "block" : "none" }}
                >
                  Configurações
                </p>
              </Link>
            </li> */}
          </div>
        </ul>
        <LightTooltip
          title="Typebot"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{
              padding: menuOpen ? "0px 12px" : "8px 12px",
              display: menuOpenAdmin ? "block" : "none",
            }}
          >
            <Link className="link_nav" to="/typebot">
              <TiFlowChildren className="icons_nav" />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Typebot
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="Conexões"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{
              padding: menuOpen ? "0px 12px" : "8px 12px",
              display: menuOpenAdmin ? "block" : "none",
            }}
          >
            <Link className="link_nav" to="/connections">
              <LuArrowRightLeft className="icons_nav" />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Conexões
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="Departamentos"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{
              padding: menuOpen ? "0px 12px" : "8px 12px",
              display: menuOpenAdmin ? "block" : "none",
            }}
          >
            <Link className="link_nav" to="/queues">
              <LuServer className="icons_nav" />
              {/* {!menuOpen && <span className="custom_tooltip dep">Departamentos</span>} */}
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Departamentos
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="Usuários"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{
              padding: menuOpen ? "0px 12px" : "8px 12px",
              display: menuOpenAdmin ? "block" : "none",
            }}
          >
            <Link className="link_nav" to="/users">
              <LuUserCog2 className="icons_nav" />
              {/* {!menuOpen && <span className="custom_tooltip users">Usuários</span>} */}
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Usuários
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="Financeiro"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{
              padding: menuOpen ? "0px 12px" : "8px 12px",
              display: menuOpenAdmin ? "block" : "none",
            }}
          >
            <Link className="link_nav" to="/financeiro">
              <MdOutlineAttachMoney className="icons_nav" />
              {/* {!menuOpen && <span className="custom_tooltip financeiro">Financeiro</span>} */}
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Financeiro
              </p>
            </Link>
          </li>
        </LightTooltip>
        <LightTooltip
          title="Configurações"
          disableHoverListener={menuOpen}
          placement="right-start"
          arrow
        >
          <li
            className="links"
            style={{
              padding: menuOpen ? "0px 12px" : "8px 12px",
              display: menuOpenAdmin ? "block" : "none",
            }}
          >
            <Link className="link_nav" to="/settings">
              <LuSettings
                className="icons_nav"
                style={{ position: "relative" }}
              />
              <p
                className="nome_link"
                style={{ display: menuOpen ? "block" : "none" }}
              >
                Configurações
              </p>
            </Link>
          </li>
        </LightTooltip>
        <div
          className="container_suporte"
          style={{ display: menuOpen ? "flex" : "none" }}
        >
          <div className="header_suporte">
            <TbHeadset className="icons_nav" />
            <p className="p_header_suporte">Precisa de ajuda?</p>
          </div>
          <p className="p_suporte">
            Clique no botão abaixo para falar com nossa equipe.
          </p>
          <a className="btn_contato" href="https://api.whatsapp.com/send?phone=5581986606673&text=Oi,%20estou%20precisando%20ajuda!" target="_blank">
            Entre em contato
          </a>
        </div>
      </ul>
    </nav>
  );
}
