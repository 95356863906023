import React, { useState, useEffect, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';

import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Tooltip } from "chart.js";
import LightTooltip from '@material-ui/core/Tooltip';
import { ContactNotes } from "../../components/ContactNotes";


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    background: "none",
    background: "#DDE3EC",
    marginTop: "27px",
    marginLeft: "16px",
    borderRadius: "20px",
    maxWidth: "97%"
  },
  button: {
    background: "transparent",
    // color: "#1A4783",
    border: "none",
    padding: "1px",
    color: "white",
    // fontWeight: "bold",
    borderRadius: "5px",
    cursor: "pointer"
  },
}));



const CRM = () => {
  const classes = useStyles();
  const history = useHistory();

  const [tags, setTags] = useState([]);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];
      //console.log(fetchedTags);
      setTags(fetchedTags);

      // Fetch tickets after fetching tags
      await fetchTickets(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const [file, setFile] = useState({
    lanes: []
  });


  const [tickets, setTickets] = useState([]);
  const { user } = useContext(AuthContext);
  const { profile, queues } = user;
  const jsonString = user.queues.map(queue => queue.UserQueue.queueId);

  const fetchTickets = async (jsonString) => {
    try {
      const { data } = await api.get("/tickets", {
        params: {
          queueIds: JSON.stringify(jsonString),
          teste: true
        }
      });
      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };

  const popularCards = (jsonString) => {
    const filteredTickets = tickets.filter(ticket => ticket.tags.length === 0);

    //console.log(filteredTickets);


    const lanes = [
      {
        id: "lane0",
        title: "Em Atendimento",
        // Esse estilo muda apenas a primeira coluna
        style: { maxHeight: 'calc(100vh - 100px)', borderRadius: "10px", boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)', width: "232px" },
        //label: "0",
        cards: filteredTickets.map(ticket => ({
          id: ticket.id.toString(),
          style: { margin: "10px", height: "120px", width: "110px", borderRadius: "10px" },
          label: "Ticket nº " + ticket.id.toString(),
          description: (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '5px' }}>
                <PermIdentityIcon style={{ marginRight: '5px' }} />
                <span>{ticket.user.name}</span>
              </div>
              <div style={{
                // backgroundColor: tag.color,
                textShadow: "1px 1px 1px #000",
                color: "white",
                borderRadius: "10px",
                marginLeft: 'auto',
                padding: '2px 5px',
                fontSize: 10
              }}>
                {/* {tag.name} */}
              </div>
              <button
                className={classes.button}
                onClick={() => handleCardClick(ticket.uuid)}
                style={{ marginTop: 'auto', alignSelf: 'flex-start' }}
              >
                <LightTooltip title="Ver detalhes do ticket" placement="bottom">
                  <VisibilityIcon htmlColor="#1A4783" />
                </LightTooltip>
              </button>
            </div>
          ),
          title: ticket.contact.name,
          draggable: true,
          href: "/tickets/" + ticket.uuid,
        })),
      },
      ...tags.map(tag => {
        const filteredTickets = tickets.filter(ticket => {
          const tagIds = ticket.tags.map(tag => tag.id);
          return tagIds.includes(tag.id);
        });

        return {
          id: tag.id.toString(),
          title: tag.name,
          cards: filteredTickets.map(ticket => ({
            style: { margin: "10px", height: "120px", width: "110px", borderRadius: "10px" },
            id: ticket.id.toString(),
            label: "Ticket nº " + ticket.id.toString(),
            description: (
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '5px' }}>
                  <PermIdentityIcon style={{ marginRight: '5px' }} />
                  <span>{ticket.user.name}</span>

                </div>
                <div style={{
                  display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: tag.color, textShadow: "1px 1px 1px #000", color: "white", borderRadius: "10px", padding: '2px 10px', fontSize: 10
                }}>
                  <span>{tag.name}</span>
                  <LightTooltip title="Ver detalhes do ticket" placement="bottom">
                    <VisibilityIcon onClick={() => handleCardClick(ticket.uuid)} htmlColor="white" style={{ cursor: 'pointer' }} />
                  </LightTooltip>
                </div>
              </div>
            ),
            title: ticket.contact.name,
            draggable: true,
            href: "/tickets/" + ticket.uuid,
          }))
          ,
          // Esse estilo muda toda as outras colunas
          style: { backgroundColor: "#E3E3E3", width: "230px", borderRadius: "10px", boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)' }
        };
      }),
    ];

    setFile({ lanes });
  };

  const handleCardClick = (uuid) => {
    //console.log("Clicked on card with UUID:", uuid);
    history.push('/tickets/' + uuid);
  };

  useEffect(() => {
    popularCards(jsonString);
  }, [tags, tickets]);


  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {

      await api.delete(`/ticket-tags/${targetLaneId}`);
      await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);

    } catch (err) {
      toast.error('Erro ao Adicionar Ticket a lane!');
    }
  };

  return (
    <div className={classes.root}>
      <Board
        style={{ backgroundColor: '#eeeeee', height: 'calc(100vh - 100px)', borderRadius: "20px", boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.1)' }}
        data={file}
        onCardMoveAcrossLanes={handleCardMove}
      />
    </div>
  );
};

export default CRM;