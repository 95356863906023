import React, { useState, useEffect, useReducer, useCallback, useRef } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    TextField,
    Paper,
    Chip,
    makeStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { debounce } from "lodash";
import TableRowSkeleton from "../TableRowSkeleton";

// Estilos personalizados para o scroll
const useStyles = makeStyles((theme) => ({
    dialogContent: {
        maxHeight: "400px",
        overflowY: "scroll",
        ...theme.scrollbarStyles, // Aplicando o estilo de scroll definido no tema
        minHeight: "400px", // Definir uma altura mínima
    },
}));

const reducer = (state, action) => {
    switch (action.type) {
        case "LOAD_CONTACTS":
            return action.payload;
        case "APPEND_CONTACTS":
            return [...state, ...action.payload];
        case "RESET":
            return [];
        default:
            return state;
    }
};

const SelectContactsModal = ({ open, onClose, onSave, existingContacts }) => {
    const classes = useStyles();
    const [contacts, dispatch] = useReducer(reducer, []);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const pageSize = 20;

    const dialogContentRef = useRef();

    useEffect(() => {
        const fetchAllTags = async () => {
            try {
                const { data } = await api.get("/tags");
                setTags(data.tags);
            } catch (err) {
                toastError(err);
            }
        };

        if (open) {
            fetchAllTags();
        }
    }, [open]);


    const fetchContacts = async () => {
        if (!open || !hasMore) return;

        setLoading(true);

        try {
            const { data } = await api.get("/contacts/", {
                params: {
                    pageNumber: page,
                    pageSize,
                    searchTerm,
                    tagIds: selectedTags.map((tag) => tag.id).join(",") // Passe os IDs das etiquetas selecionadas como uma string separada por vírgulas
                }
            });

            const filteredContacts = data.contacts.filter(
                (contact) => !existingContacts.includes(contact.number)
            );

            if (page === 1) {
                dispatch({ type: "LOAD_CONTACTS", payload: filteredContacts });
            } else {
                dispatch({ type: "APPEND_CONTACTS", payload: filteredContacts });
            }

            setHasMore(data.contacts.length === pageSize);
        } catch (err) {
            toastError(err);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (open) {
            fetchContacts();
        }
    }, [open, page, searchTerm, existingContacts, hasMore, selectedTags]);

    useEffect(() => {
        if (open) {
            setSelectedContacts([]);
            setSelectAll(false);
            setPage(1); // Reset the page to 1
            setHasMore(true); // Ensure hasMore is true to start with
            setSearchTerm(""); // Reset search term
            setSelectedTags([]); // Reset selected tags
            dispatch({ type: "RESET" });
        }
    }, [open]);

    const handleToggle = (contactId) => {
        setSelectedContacts((prev) =>
            prev.includes(contactId)
                ? prev.filter((id) => id !== contactId)
                : [...prev, contactId]
        );
    };

    const handleSelectAllToggle = () => {
        if (selectAll) {
            setSelectedContacts([]);
        } else {
            setSelectedContacts(contacts.map((contact) => contact.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSave = () => {
        onSave(selectedContacts);
        onClose();
    };

    const handleScroll = () => {
        if (
            dialogContentRef.current &&
            dialogContentRef.current.scrollTop + dialogContentRef.current.clientHeight >=
            dialogContentRef.current.scrollHeight - 100 &&
            !loading &&
            hasMore
        ) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleTagChange = (event, value) => {
        setSelectedTags(value);
        setPage(1); // Reset page when tags change
        setHasMore(true);
        dispatch({ type: "RESET" });
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Selecionar Contatos ({selectedContacts.length} selecionados)</DialogTitle>
            <DialogContent
                ref={dialogContentRef}
                onScroll={handleScroll}
                className={classes.dialogContent}
            >
                <Paper style={{ padding: 12 }}>
                    <Autocomplete
                        multiple
                        size="small"
                        options={tags}
                        value={selectedTags}
                        onChange={handleTagChange}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}  // Define como comparar as opções com o valor selecionado
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    key={index}
                                    variant="outlined"
                                    style={{ backgroundColor: option.color || '#eee', textShadow: '1px 1px 1px #000', color: 'white' }}
                                    label={option.name}
                                    {...getTagProps({ index })}
                                    size="small"
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" placeholder="Selecione a Etiqueta" />
                        )}
                        PaperComponent={({ children }) => (
                            <Paper style={{ width: 400, marginLeft: 12 }}>
                                {children}
                            </Paper>
                        )}
                    />
                </Paper>
                <List>
                    <ListItem
                        button
                        onClick={handleSelectAllToggle}
                        style={{
                            backgroundColor: "#f0f0f0",
                            fontWeight: "bold",
                        }}
                    >
                        <ListItemText primary="Selecionar Todos" />
                        <ListItemSecondaryAction>
                            <Checkbox
                                edge="end"
                                checked={selectAll}
                                onChange={handleSelectAllToggle}
                                style={{ color: "#1A4783" }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>

                    {contacts.length > 0 && contacts.map((contact, index) => (
                        <ListItem
                            key={index}
                            button
                            onClick={() => handleToggle(contact.id)}
                        >
                            <ListItemText
                                primary={contact.name}
                                secondary={
                                    <>
                                        {contact.number}
                                        <div style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',  // Permite que as tags sejam quebradas em várias linhas se necessário
                                            justifyContent: 'left',  // Centraliza as tags dentro da célula

                                        }}>
                                            {contact.tags.map((tag) => (
                                                <span
                                                    key={tag.id}
                                                    style={{
                                                        margin: '2px',  // Ajusta a margem para dar espaço entre as tags
                                                        marginBottom: '4px',  // Adiciona espaço entre as linhas de tags
                                                        backgroundColor: tag.color || 'black',
                                                        color: 'white',
                                                        padding: '2px 4px',
                                                        borderRadius: '4px',
                                                        display: 'inline-block',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    {tag.name}
                                                </span>
                                            ))}
                                        </div>
                                    </>
                                }
                            />
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    checked={selectedContacts.includes(contact.id)}
                                    onChange={() => handleToggle(contact.id)}
                                    style={{ color: "#1A4783" }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))
                    }
                </List>
                {loading && <TableRowSkeleton columns={2} />}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="secondary"
                    style={{ border: "2px solid" }}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={handleSave}
                    color="primary"
                    style={{
                        border: "2px solid",
                        backgroundColor: "#1A4783",
                        color: "white",
                    }}
                    disabled={selectedContacts.length === 0}
                >
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(SelectContactsModal);
