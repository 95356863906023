import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, TextField, DialogContent, DialogActions, Grid, IconButton } from '@material-ui/core';
import PropType from 'prop-types'
import Dialog from '../Dialog';
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { i18n } from '../../translate/i18n';
import { makeStyles } from '@material-ui/core/styles';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { AuthContext } from "../../context/Auth/AuthContext";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { isNil, isObject, has, get, head } from 'lodash';
import useQuickMessages from '../../hooks/useQuickMessages';

const MessageSchema = Yup.object().shape({
    shortcode: Yup.string()
        .min(3, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    message: Yup.string()
        .min(3, "Too Short!")
        .required("Required")
});

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '350px',
        },
    },
    list: {
        width: '100%',
        maxWidth: '350px',
        maxHeight: '200px',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        width: '100%'
    }
}));


function QuickMessageDialog(props) {
    const classes = useStyles()

    const initialMessage = {
        id: null,
        shortcode: '',
        message: '',
        attachment: null
    };

    const {
        list: listMessages,
        save: saveMessage,
        update: updateMessage,
        deleteRecord: deleteMessage,
        uploadMedia,
        removeMedia
    } = useQuickMessages();

    const { modalOpen, editMessage, onClose, messageSelected, loadingQuickMessages } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [message, setMessage] = useState(initialMessage);
    const [loading, setLoading] = useState(false);
    const attachmentRef = useRef(null);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        verifyAndSetMessage()
        setDialogOpen(modalOpen)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpen])

    useEffect(() => {
        verifyAndSetMessage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageSelected])

    const messageSelectedIsValid = () => {
        return isObject(messageSelected) && has(messageSelected, 'id') && !isNil(get(messageSelected, 'id'))
    }

    const verifyAndSetMessage = () => {
        if (messageSelectedIsValid()) {
            const { id, message, shortcode, mediaName } = messageSelected
            setMessage({ id, message, shortcode, attachment: mediaName ? { name: mediaName } : null });
        } else {
            setMessage(initialMessage)
        }
    }

    const handleClose = () => {
        onClose()
        setLoading(false)
    }

    const handleSave = async (values) => {
        if (messageSelectedIsValid()) {
            await updateMessage({
                ...messageSelected,
                ...values,
                userId: user.id
            });

            if (values.attachment) {

                const formData = new FormData();
                formData.append("file", values.attachment);

                await uploadMedia(formData, messageSelected.id);

            }

        } else {
            const data = await saveMessage({ ...values, userId: user.id });

            if (values.attachment) {

                const formData = new FormData();
                formData.append("file", values.attachment);

                await uploadMedia(formData, data.id);

            }

        }
        handleClose();
        loadingQuickMessages();
    }

    const handleAttachmentFile = (e, setFieldValue) => {

        const file = head(e.target.files);

        if (file) {
            setFieldValue('attachment', file);
        }

    };

    return (
        <Dialog
            title="Mensagem Rápida"
            modalOpen={dialogOpen}
            onClose={handleClose}
        >
            <Formik
                initialValues={message}
                enableReinitialize={true}
                validationSchema={MessageSchema}
                onSubmit={(values, actions) => {
                    setLoading(true)
                    setTimeout(() => {
                        handleSave(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >

                {({ touched, errors, setFieldValue, values }) => (
                    <Form>
                        <DialogContent className={classes.root} dividers>
                            <Grid direction="column" container>
                                <Grid item>
                                    <Field
                                        as={TextField}
                                        name="shortcode"
                                        label={i18n.t("quickMessages.dialog.shortcode")}
                                        error={touched.shortcode && Boolean(errors.shortcode)}
                                        helperText={touched.shortcode && errors.shortcode}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item>
                                    <Field
                                        as={TextField}
                                        name="message"
                                        rows={6}
                                        label={i18n.t("quickMessages.dialog.message")}
                                        multiline={true}
                                        error={touched.message && Boolean(errors.message)}
                                        helperText={touched.message && errors.message}
                                        variant="outlined"
                                    />
                                </Grid>

                                {values.attachment !== null ?

                                    <Grid item>
                                        <Button style={{ cursor: 'inherit' }} startIcon={<AttachFileIcon />}>
                                            {values.attachment != null && values.attachment?.name}
                                        </Button>

                                        {values.attachment && (
                                            <IconButton
                                                onClick={async () => {
                                                    setFieldValue("attachment", null);

                                                    await removeMedia(messageSelected.id);
                                                    loadingQuickMessages();

                                                }}
                                                color="secondary"
                                            >
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        )}
                                    </Grid>

                                    :

                                    <Grid item style={{ marginLeft: '8px' }}>

                                        <Button
                                            color="primary"
                                            onClick={() => attachmentRef.current.click()}
                                            disabled={loading}
                                            variant="outlined"
                                        >
                                            {i18n.t("campaigns.dialog.buttons.attach")}
                                        </Button>
                                    </Grid>

                                }

                                <div style={{ display: "none" }}>
                                    <input
                                        type="file"
                                        ref={attachmentRef}
                                        onChange={(e) => handleAttachmentFile(e, setFieldValue)}
                                    />
                                </div>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancelar
                            </Button>
                            <ButtonWithSpinner loading={loading} type="submit" variant="contained" autoFocus style={{ backgroundColor: "#1A4783", color: "white" }}>
                                Salvar
                            </ButtonWithSpinner>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

QuickMessageDialog.propType = {
    modalOpen: PropType.bool,
    onClose: PropType.func
}

export default QuickMessageDialog;