// Página de Tarefas
import React from 'react';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import { makeStyles, Button, Paper, withStyles, Tooltip, Grid } from "@material-ui/core";
import { TasksTable } from '../../components/TasksTable';
import { TasksDialog } from '../../components/TasksDialog';
import { useState } from 'react';
import ConfirmationModal from '../../components/ConfirmationModal';
import api from '../../services/api';
import { toast } from 'react-toastify';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

export const Tasks = () => {

  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [showOnDeleteDialog, setShowOnDeleteDialog] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [reloadTasks, setReloadTasks] = useState(false);


  const handleOpenToEdit = (task) => {
    setSelectedTask(task);
    setModalOpen(true);
  };

  const handleDelete = async (task) => {

    try {

      await api.delete(`/tasks/${task.id}`);

      toast.info('Tarefa excluida com sucesso!');
      handleReloadTasks();

    } catch (error) {

      toast.error('Erro ao excluir tarefa!');

    }

  };

  const handleReloadTasks = () => {

    setReloadTasks(prev => !prev);

  }

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: "#153969",
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <>
      <TasksDialog
        openDialog={modalOpen}
        setOpen={setModalOpen}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        reloadTasks={handleReloadTasks}
      />

      <ConfirmationModal
        title="Excluir Registro"
        open={showOnDeleteDialog}
        onClose={setShowOnDeleteDialog}
        onConfirm={async () => {
          await handleDelete(selectedTask);
        }}
      >
        Deseja realmente excluir este registro?
      </ConfirmationModal>

      <MainContainer>
        <MainHeader>
          <Grid style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }} container>
            <Grid xs={8} sm={11} item>
              <h2 style={{fontSize: "18px", fontWeight: "500", paddingLeft: "10px"}}>Tarefas</h2>
            </Grid>
           
            <Grid  item>
             
              <Button

              
                onClick={() => setModalOpen(true)}
                style={{width:"max-content"}}
              >
                
                <LightTooltip arrow title="Nova Tarefa" placement="bottom">
                  <AddCircleOutlineIcon />
                </LightTooltip>
              </Button>
             
            </Grid>
          </Grid>
         
        </MainHeader>

        <Paper className={classes.mainPaper} variant="outlined" style={{ borderRadius: "20px" }}>
          <TasksTable
            editTask={handleOpenToEdit}
            reloadTasks={reloadTasks}
            deleteTask={(task) => {
              setSelectedTask(task);
              setShowOnDeleteDialog(true);
            }}
          />
        </Paper>

      </MainContainer>
    </>

  );
}